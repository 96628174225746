import React from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../Breadcrumbs'

export const CompaniesHero = ({ heroTitle, breadcrumbs }) => {
  return (
    <CompaniesHeroBlock>
      <div className="CompaniesHero__content">
        <h1 className="CompaniesHero__title">{ heroTitle }</h1>
        <Breadcrumbs breadcrumbs={ breadcrumbs } variant="company"/>
      </div>
    </CompaniesHeroBlock>
  )
}

export default CompaniesHero

const CompaniesHeroBlock = styled.section `
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 96%;
  max-width: 1500px;
  overflow: hidden;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    padding-top: 3rem;
    padding-bottom: 2rem;
    align-items: center;
    text-align: center;
  }

  .CompaniesHero__content {
    @media only screen and (max-width: 780px) {
      margin-left: auto;
      margin-right: auto;
      width: 94%;
    }
  }

  .CompaniesHero__img {
    margin-right: 3rem;
    width: 200px;
    height: 200px;
    border-radius: 1rem;

    @media only screen and (max-width: 1080px) {
      width: 120px;
    }

    @media only screen and (max-width: 780px) {
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }

  .CompaniesHero__title {
    font-size: 3.8rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    letter-spacing: 0;

    @media only screen and (max-width: 1080px) {
      margin-bottom: 1.6rem;
      font-size: 3.2rem;
    }

    @media only screen and (max-width: 480px) {
      font-size: 2.8rem;
    }
  }

  .CompaniesHero__small {
    display: inline-block;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    background-color: ${props => props.theme.skyExtraLight};
    color: ${props => props.theme.blueGray};

    @media only screen and (max-width: 480px) {
      margin-bottom: 1rem;
    }
  }
`