import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import styled from 'styled-components'
import Img from '../Img'

const CompanyRow = ({ company }) => {
  return (
    <CompanyRowItem>
      <Link className="CompanyRow_item" to={ company.uri }>
        <div className="CompanyRow_img">
          <Img
            localFile={ company.featuredImage.node.localFile }
            alt={ `${company.title} | ${company.companyFields.companyTiker}` }
           />
        </div>
        <div className="CompanyRow_body">
          <strong className="CompanyRow_ticker">{ company.companyFields.companyTiker }</strong>
          <h5 className="CompanyRow_title">{ company.title }</h5>
          <div className="CompanyRow_text">{ parse(company.excerpt) }</div>
        </div>
      </Link>
    </CompanyRowItem>
  )
}

export default CompanyRow

const CompanyRowItem = styled.div `

  flex: 1 1 100%;
  width: 100%;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: ${props => props.theme.white};
  transition: ${props => props.theme.transition};

  .CompanyRow_item {
    display: flex;
  }

  .CompanyRow_ticker {
    display: inline-block;
    padding: 0.4rem 1rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: ${props => props.theme.black};
    border: 1px solid #bdd6e9;
    border-radius: 0.3rem;
    background-color: #deedf7;
    transition: ${props => props.theme.transition};

    @media only screen and (max-width: 480px) {
      padding: 0.5rem;
      font-size: 1.4rem;
    }
  }

  .CompanyRow_img {
    margin-right: 2rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
  }

  .CompanyRow_body {

  }

  .CompanyRow_title {
    margin-bottom: 0;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: 0;
    color: ${props => props.theme.black};
    transition: ${props => props.theme.transition};

    @media only screen and (max-width: 480px) {
      line-height: 1.4;
    }
  }

  .CompanyRow_text {
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 400;
    color: ${props => props.theme.grayDark};
    transition: ${props => props.theme.transition};

    @media only screen and (max-width: 480px) {
      margin-top: 0.5rem;
    }
  }
`
