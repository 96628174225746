import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import CompaniesHero from '../components/Company/CompaniesHero'
import CompaniesList from '../components/Company/CompaniesList'
import CompaniesContent from '../components/Company/CompaniesContent'
import CompaniesBody from '../components/Company/CompaniesBody'
import CompaniesMain from '../components/Company/CompaniesMain'
import CompaniesSector from '../components/Company/CompaniesSector'
// import CompaniesExchanges from '../components/Company/CompaniesExchanges'
import SideBarContent from '../components/SideBar/SideBarContent'
import SideBar from '../components/SideBar/SideBar'

const CompaniesSectorTemplate = ({ pageContext, data }) => {

  const { title } = pageContext

  const { seo } = data.allWpCompany.nodes[0].sectors.nodes[0]

  return (
    <Layout>
      <CompaniesHero heroTitle={ title } />
      <CompaniesContent>
        <CompaniesBody>
          <SideBar>
            <SideBarContent>
              <CompaniesSector title="Другие сектора" />
            </SideBarContent>
          </SideBar>
          <CompaniesMain>
            <CompaniesList companies={ data.allWpCompany.nodes } list />
          </CompaniesMain>
        </CompaniesBody>
      </CompaniesContent>
    </Layout>
  )
}

export default CompaniesSectorTemplate

export const listSectorCompanies = graphql `
  query ($slug: String) {
    allWpCompany(
      filter: {sectors: {nodes: {elemMatch: {slug: {eq: $slug}}}}},
      sort: {fields: ancestors___nodes___date, order: DESC},
    ) {
      nodes {
        id
        title
        uri
        excerpt
        companyFields {
          companyAddress
          companyPhone
          companySite
          companyTiker
        }
        sectors {
          nodes {
            name
            slug
            seo {
              canonical
              cornerstone
              focuskw
              fullHead
              metaDesc
              metaKeywords
              metaRobotsNofollow
              metaRobotsNoindex
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphSiteName
              opengraphTitle
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              breadcrumbs {
                text
                url
              }
            }
          }
        }
        countries {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100, height: 100, placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`