import React from 'react'
import styled from 'styled-components'
import CompanyCard from './CompanyCard'
import CompanyRow from './CompanyRow'

const CompaniesList = ({ companies, list }) => {

  return (
    <CompaniesListBlock>
      { list
          ? companies.map(company => <CompanyRow key={ company.id } company={ company } /> )
          : companies.map(company => <CompanyCard key={ company.id } company={ company } />)
      }
    </CompaniesListBlock>
  )
}

export default CompaniesList

const CompaniesListBlock = styled.div `
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`