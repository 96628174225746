import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import styled from 'styled-components'
import Img from '../Img'

export const CompanyCard = ({ company }) => {
  return (
    <CompanyCardItem>
      <Link to={ company.uri }>
        <div className="CardCompany__header">
          <strong className="CardCompany__ticker">{ company.companyFields.companyTiker }</strong>
          <div className="CardCompany__img">
            <Img
              localFile={ company.featuredImage.node.localFile }
              alt={ `${company.title} | ${company.companyFields.companyTiker}` }
             />
          </div>
        </div>
        <div className="CardCompany__body">
          <h5 className="CardCompany__title">{ company.title }</h5>
          <div className="CardCompany__text">{ parse(company.excerpt) }</div>
        </div>
      </Link>
    </CompanyCardItem>
  )
}

export default CompanyCard

const CompanyCardItem = styled.div `
  flex: 1 1 20%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  transition: ${props => props.theme.transition};

  @media only screen and (max-width: 1580px) {
    flex: 1 1 20%;
  }

  @media only screen and (max-width: 1580px) {
    flex: 1 1 26%;
  }

  @media only screen and (max-width: 680px) {
    flex: 1 1 33%;
  }

  @media only screen and (max-width: 480px) {

  }

  @media only screen and (max-width: 380px) {
    margin-bottom: 1rem;
    flex: 1 1 100%;
    max-width: none;
    width: 100%;
  }

  &:hover {
    border-color: #abb7c1;

    .CardCompany__ticker {
      background-color: ${props => props.theme.black};
      color: ${props => props.theme.white};
      border-color: ${props => props.theme.black};
    }

    .CardCompany__text p {
      color: ${props => props.theme.black};
    }
  }

  .CardCompany__header {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e9eb;

    @media only screen and (max-width: 480px) {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }
  }

  .CardCompany__ticker {
    padding: 0.4rem 1rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: ${props => props.theme.black};
    border: 1px solid #bdd6e9;
    border-radius: 0.3rem;
    background-color: #deedf7;
    transition: ${props => props.theme.transition};

    @media only screen and (max-width: 480px) {
      padding: 0.5rem;
      font-size: 1.4rem;
    }
  }

  .CardCompany__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    @media only screen and (max-width: 480px) {
      width: 30px;
      height: 30px;
    }
  }

  .CardCompany__small {
    margin-bottom: 1.2rem;

    @media only screen and (max-width: 480px) {
      margin-bottom: 0.8rem;
    }
  }

  .CardCompany__title {
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: 0;
    color: ${props => props.theme.black};
    transition: ${props => props.theme.transition};

    @media only screen and (max-width: 480px) {
      line-height: 1.4;
    }
  }

  .CardCompany__body {
    padding: 2rem;

    @media only screen and (max-width: 480px) {
      padding-top: 1rem;
    }
  }

  .CardCompany__text p {
    margin-top: 1rem;
    font-size: 1.3rem;
    line-height: 1.4;
    font-weight: 400;
    color: ${props => props.theme.grayDark};
    transition: ${props => props.theme.transition};

    @media only screen and (max-width: 480px) {
      margin-top: 0.5rem;
    }
  }
`
