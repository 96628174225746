import React from 'react'
import styled from 'styled-components'

export const CompaniesContent = ({ children }) => {
  return (
    <CompaniesContentBlock>
      { children }
    </CompaniesContentBlock>
  )
}

export default CompaniesContent

const CompaniesContentBlock = styled.section `
  width: 96%;
  max-width: 1500px;
  position: relative;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
`
