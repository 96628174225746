import React from 'react'
import styled from 'styled-components'

export const CompaniesMain = ({ children }) => {
  return (
    <CompaniesMainBlock>
      { children }
    </CompaniesMainBlock>
  )
}

export default CompaniesMain

const CompaniesMainBlock = styled.div `
  margin-left: 2rem;
`
