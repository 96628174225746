import React from 'react'
import styled from 'styled-components'

const CompaniesBody = ({ children }) => {
  return (
    <CompaniesBodyBlock>
      { children }
    </CompaniesBodyBlock>
  )
}

export default CompaniesBody

const CompaniesBodyBlock = styled.div `
  display: flex;
`