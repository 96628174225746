import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

const counterSectors = (sectors) => {
  const allSectors = sectors.map(sector => sector.sectors.nodes)
    .flat()
    .reduce((accum, curVal) => {

      !accum[curVal.id]
        ? accum[curVal.id] = { ...curVal, count: 1 }
        : accum[curVal.id] = { ...curVal, count: accum[curVal.id].count + 1 }

      return accum
    }, {})

  return Object.values(allSectors).sort((a, b) => a.count - b.count)
}

const CompaniesSector = ({ title }) => {
  const { sectors } = useStaticQuery(graphql `
    query {
      sectors: allWpCompany {
        nodes {
          sectors {
            nodes {
              id
              name
              uri
            }
          }
        }
      }
    }
  `)

  const sectorsArray = counterSectors(sectors.nodes).reverse()

  return (
    <CompaniesSectorBlock>
      <h4 className="CompaniesSector__title">{ title }</h4>
      <ul className="CompaniesSector__list">
        <li className="CompaniesSector__list_item">
          <Link to="/companies">Все</Link>
          <span className="CompaniesSector__count">{ sectors.nodes.length }</span>
        </li>
        {sectorsArray.map(sector => (
          <li className="CompaniesSector__list_item" key={ sector.id }>
            <Link to={sector.uri}>
              { sector.name }
              <span className="CompaniesSector__count">{ sector.count }</span>
            </Link>
          </li>
        ))}
      </ul>
    </CompaniesSectorBlock>
  )
}

export default CompaniesSector

const CompaniesSectorBlock = styled.div `

  .CompaniesSector__title {
    margin-bottom: 0;
  }

  .CompaniesSector__list {
    margin-top: 0;

    * + li {
      margin-top: 1rem;
    }
  }

  .CompaniesSector__list_item {
    font-size: 1.4rem;

    a {
      color: ${props => props.theme.black};
    }
  }

  .CompaniesSector__count {
    display: inline-block;
    margin-left: 0.5rem;
    padding: 0.5rem;
    background-color: ${props => props.theme.grayExtraLight};
    border-radius: 10px;
    border: 1px solid ${props => props.theme.grayLight};
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    color: ${props => props.theme.blueGray};
  }
`
